<head>
    <meta charset="UTF-8" />
    <title>NVIDIA GeForce NOW</title>
    <script>
        function sendErrorMessage() {
            const errorMessage = {
                error: {
                    errorCode: '?ERROR_CODE',
                    errorText: '?ERROR_TEXT',
                    failedUrl: '?FAILED_URL'
                }
            };
            window.opener.postMessage(errorMessage, '*');
        }
    </script>
</head>
<body style="background-color: #1a1a1a; font-family: Roboto, sans-serif;" onload="sendErrorMessage()">
    <div style="position: absolute; top: 50%; left: 50%; margin-right: 50%; transform: translate(-50%, -50%);">
        <p aria-hidden="true" style="color: rgba(255, 255, 255, .3); width: 410px; text-align: center;">
            <img
                src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNWE1YTVhIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgMkM2LjQ4IDIgMiA2LjQ4IDIgMTJzNC40OCAxMCAxMCAxMCAxMC00LjQ4IDEwLTEwUzE3LjUyIDIgMTIgMnptMSAxNWgtMnYtNmgydjZ6bTAtOGgtMlY3aDJ2MnoiLz48L3N2Zz4="
            />
        </p>
        <p style="color: rgba(255, 255, 255, .3); width: 410px; text-align: center;">
            #pageCouldNotBeLoaded
        </p>
    </div>
</body>
