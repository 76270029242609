import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HigModule } from 'hig';
import { NetworkTipsComponent } from './network-tips.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
    declarations: [NetworkTipsComponent],
    imports: [CommonModule, TranslateModule, FlexLayoutModule, HigModule, A11yModule],
    exports: [NetworkTipsComponent]
})
export class NetworkTipsModule {}
