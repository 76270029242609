import {
    Attribute,
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    AfterViewInit,
    HostBinding
} from '@angular/core';
import {
    MatLegacySlider as MatSlider,
    MatLegacySliderChange as MatSliderChange
} from '@angular/material/legacy-slider';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import * as moment from 'moment';

export enum NvHigSliderTextType {
    Integer = 'integer',
    Decimal = 'decimal',
    Time = 'time',
    Percent = 'percent'
}

const InputValidationPatterns: { [key in NvHigSliderTextType]: RegExp } = {
    [NvHigSliderTextType.Integer]: /^-?\d*$/,
    [NvHigSliderTextType.Decimal]: /^-?\d*\.*\d*$/,
    [NvHigSliderTextType.Time]: /^-?\d*[:]?\d*$/,
    [NvHigSliderTextType.Percent]: /^-?\d*$/
};

@Component({
    selector: 'nv-hig-slider',
    templateUrl: './nv-hig-slider.component.html',
    styleUrls: ['./nv-hig-slider.component.scss']
})
export class NvHigSliderComponent implements OnInit, AfterViewInit {
    @Input()
    get min(): number {
        return this._min;
    }
    set min(val: number) {
        this._min = coerceNumberProperty(val, this._min);
    }
    private _min = 0;

    @Input()
    get max(): number {
        return this._max;
    }
    set max(val: number) {
        this._max = coerceNumberProperty(val, this._max);
    }
    private _max = 100;

    @Input()
    get step(): number {
        return this._step;
    }
    set step(val: number) {
        this._step = coerceNumberProperty(val, this._step);
        this.forceSliderTickUpdate();
    }
    private _step = 1;

    @Input()
    get default(): number {
        return this._default;
    }
    set default(val: number) {
        this._default = coerceNumberProperty(val, this._default);
    }
    private _default = undefined;

    @Input() disabled = false;
    @Input() thumbLabel = false;
    @Input() animating = false;

    @Input()
    get tickInterval() {
        return this._tickInterval;
    }
    set tickInterval(value: 'auto' | number) {
        if (value === 'auto') {
            this._tickInterval = 'auto';
        } else if (typeof value === 'number' || typeof value === 'string') {
            this._tickInterval = coerceNumberProperty(value, this._tickInterval as number);
        } else {
            this._tickInterval = 0;
        }
        console.log('tickinterval: ', this._tickInterval);
        this.forceSliderTickUpdate();
    }
    private _tickInterval: 'auto' | number = 0;

    @Input()
    get value() {
        return this._value;
    }
    set value(val: number) {
        this._value = coerceNumberProperty(val, this._value);
        setTimeout(() => this.sliderMoved({ source: this.slider, value: this.value }), 0);
    }
    private _value = 0;

    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

    @Input() valueEntry = false;

    @Input()
    set formatTextAs(val: NvHigSliderTextType | string) {
        this._formatTextAs = (<any>NvHigSliderTextType)[val] || NvHigSliderTextType.Integer;
    }
    get formatTextAs() {
        return this._formatTextAs;
    }
    private _formatTextAs: NvHigSliderTextType = NvHigSliderTextType.Integer;

    // Compat with mat-slider
    // eslint-disable-next-line
    @Output() readonly change: EventEmitter<MatSliderChange> = new EventEmitter<MatSliderChange>();
    // eslint-disable-next-line
    @Output() readonly input: EventEmitter<MatSliderChange> = new EventEmitter<MatSliderChange>();

    @ViewChild('slider', { static: true }) slider: MatSlider;
    @HostBinding('tabindex') realTabIndex: string;

    tabindex: number = undefined;

    sliderStep = 0;
    inputValue: string = undefined;
    textFocused = false;
    mouseDown = false;
    initializing = true;

    private afterInit = false;
    private textValueValidation = '';

    constructor(@Attribute('tabindex') tab: string) {
        this.tabindex = parseInt(tab, 10);
        if (Number.isNaN(this.tabindex)) {
            this.tabindex = undefined;
        }
        this.realTabIndex = '-1';
    }

    ngOnInit() {}

    ngAfterViewInit() {
        this.afterInit = true;
        setTimeout(() => {
            this.forceSliderTickUpdate();
            this.sliderMoved({ source: this.slider, value: this.value });
        }, 0);
        setTimeout(() => (this.initializing = false), 100);
    }

    private forceSliderTickUpdate() {
        if (this.afterInit && this.slider) {
            // this gets the slider ticks to display properly
            // otherwise, they are only shown on first mouse entry
            this.slider.step = this.step;
            this.sliderStep = this.step;
            this.slider._onMouseenter();
        }
    }

    onTextFocus() {
        this.textFocused = true;
    }

    onTextBlur() {
        this.textFocused = false;
    }

    textChanged(event: Event) {
        let newValue = (<HTMLInputElement>event.target).value;
        if (this.formatTextAs === NvHigSliderTextType.Time) {
            const time = moment(newValue, 'mm:ss');
            newValue = time.minutes() * 60 + time.seconds() + '';
        }

        let updatedVal = 0;
        if (this.formatTextAs === NvHigSliderTextType.Decimal) {
            updatedVal = (parseFloat(newValue) || 0) * 100;
            if (this.step) {
                updatedVal = Math.round((updatedVal - this.min * 100) / (this.step * 100)) * this.step + this.min;
            }
        } else {
            updatedVal = parseInt(newValue, 10) || 0;
            if (this.step) {
                updatedVal = Math.round((updatedVal - this.min) / this.step) * this.step + this.min;
            }
        }
        updatedVal = Math.min(this.max, Math.max(updatedVal, this.min));

        this.value = updatedVal;
        this.sliderChanged({ source: this.slider, value: this.value });
        event.stopImmediatePropagation();
    }

    sliderChanged(newValue: MatSliderChange) {
        this.valueChange.emit(this.value);
        this.change.emit(newValue);
    }

    sliderMoved(newValue: MatSliderChange) {
        if (!newValue || newValue.value === undefined) {
            return;
        }

        if (this.formatTextAs === NvHigSliderTextType.Time) {
            let val = moment({ seconds: 0 });
            val = val.second(newValue.value);
            this.inputValue = val.format('mm:ss');
        } else if (this.formatTextAs === NvHigSliderTextType.Percent) {
            this.inputValue = newValue.value + ' %';
        } else {
            this.inputValue = newValue.value + '';
        }
        this.textValueValidation = this.inputValue;

        this.input.emit(newValue);
    }

    textInput(event: Event) {
        const pattern =
            InputValidationPatterns[this.formatTextAs] || InputValidationPatterns[NvHigSliderTextType.Integer];
        const element = event.target as HTMLInputElement;
        if (element && !pattern.test(element.value)) {
            element.value = this.textValueValidation;
        } else {
            this.textValueValidation = element.value;
        }
        event.stopImmediatePropagation();
    }

    onKeyDown(event: KeyboardEvent) {
        if (this.default && event.code === 'Period') {
            this.value = this.default;
            this.sliderChanged({ source: this.slider, value: this.value });
        }
    }
}
