import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NvAppBarComponent } from './components/app-bar/nv-app-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProductTourModule } from 'product-tour';
import { DomSanitizer } from '@angular/platform-browser';
import { HigModule } from 'hig';

export function initializeIcons(reg: MatIconRegistry, san: DomSanitizer): Promise<any> {
    reg.addSvgIcon('gfnBranding', san.bypassSecurityTrustResourceUrl('./assets/img/nv-gfn-logo_v2.svg'));
    return Promise.resolve();
}

export function initializeIconFactory(reg: MatIconRegistry, san: DomSanitizer) {
    const x = () => initializeIcons(reg, san);
    return x;
}

export let iconFactoryProvider = {
    provide: APP_INITIALIZER,
    useFactory: initializeIconFactory,
    deps: [MatIconRegistry, DomSanitizer],
    multi: true
};

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatTooltipModule,
        ProductTourModule,
        TranslateModule,
        HigModule
    ],
    declarations: [NvAppBarComponent],
    exports: [NvAppBarComponent],
    providers: [iconFactoryProvider]
})
export class AppBarModule {}
