<div class="nv-hig" fxFlex="row" fxLayoutAlign="start start">
    <mat-slider
        #slider
        fxFlex
        [min]="min"
        [max]="max"
        [step]="sliderStep"
        [disabled]="disabled"
        [thumbLabel]="thumbLabel"
        [tickInterval]="tickInterval"
        [(value)]="value"
        (input)="sliderMoved($event)"
        (change)="sliderChanged($event)"
        [class.cdk-mouse-focused]="textFocused"
        [class.nv-mouse-down]="mouseDown"
        (mousedown)="mouseDown = true"
        (window:mouseup)="mouseDown = false"
        (keydown)="onKeyDown($event)"
        [class.hig-slider-no-animation]="animating || initializing"
        [tabIndex]="tabindex"
        nvMatSliderKeyboardFixup
    >
    </mat-slider>
    <input
        type="text"
        *ngIf="valueEntry"
        fxFlex="none"
        class="slider-text-entry font-body2"
        [disabled]="disabled"
        [(ngModel)]="inputValue"
        (focus)="onTextFocus()"
        (blur)="onTextBlur()"
        (change)="textChanged($event)"
        (input)="textInput($event)"
        [attr.tabindex]="tabindex"
    />
</div>
