import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { LocaleService } from 'crimson';
import { Subscription } from 'rxjs';
import { CommonDynamicRedirect, CommonRedirectShortLinksMap } from '../../../redirect';
import { EcomDialogData } from '../../ecommerce';

@Component({
    selector: 'gfn-membership-refresh-error',
    templateUrl: './membership-refresh-error.component.html',
    styleUrls: ['./membership-refresh-error.component.scss']
})
export class MembershipRefreshErrorComponent implements OnInit, OnDestroy {
    @Input() data: EcomDialogData;
    @Output() closeButtonClicked = new EventEmitter<any>();

    serverStatusUrl: string;
    serverStatusShortUrl = CommonRedirectShortLinksMap[CommonDynamicRedirect.GfnServerStatus];

    private localeSubscription: Subscription;

    constructor(private localeService: LocaleService) {}

    ngOnInit() {
        this.localeSubscription = this.localeService.localeChanged.subscribe((locale: string) => {
            this.serverStatusUrl =
                CommonDynamicRedirect.BaseUrl + locale + '&page=' + CommonDynamicRedirect.GfnServerStatus;
        });
    }

    closeClicked() {
        this.closeButtonClicked.emit();
    }

    ngOnDestroy() {
        if (this.localeSubscription) {
            this.localeSubscription.unsubscribe();
        }
    }
}
